@import './src/styles/abstracts/mixins';

.ControlComponentLayout {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: auto;

  .ControlComponent {
    width: 40%;
    padding: 1.8rem 0;
    @include respond(tab-port) {
      width: 30%;
    }
    @include respond(phone) {
      width: 40%;
    }
  }
  .FullWidthComponent {
    width: 85%;
    padding: 1.6rem 0;
    @include respond(tab-port) {
      width: 30%;
    }
    @include respond(phone) {
      width: 85%;
      padding: 2.5em 0;
    }
  }

  .ButtonGroup {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
    gap: 1em;
  }
}
// 0 - 600px:      Phone
// 600 - 900px:    Tablet portrait
// 900 - 1200px:   Tablet landscape
// [1200 - 1800] is where our normal styles apply
// 1800px + :      Big desktop
