@import './src/styles/abstracts/mixins';

.Layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  @include respond(tab-port) {
    flex-direction: column-reverse;
    overflow-y: hidden;
  }

  .RecipeControlPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
    min-width: 400px;
    width: 30%;
    background-color: #1a202c; //try d2d2d2
    @include respond(tab-port) {
      position: relative;
      width: 100%;
      height: 35%;
      padding: 1em 0;
      min-width: 0;
    }
  }

  .RecipeDisplay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #edf2f7;

    @include respond(tab-port) {
      height: 65%;
    }
  }
}

// 0 - 600px:      Phone
// 600 - 900px:    Tablet portrait
// 900 - 1200px:   Tablet landscape
// [1200 - 1800] is where our normal styles apply
// 1800px + :      Big desktop
