@import './src/styles/abstracts/mixins';
.footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 1em;
  text-align: center;

  @include respond(tab-port) {
    visibility: hidden;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    color: #2d3748;
  }

  .border {
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: #1a202c;
  }

  .border::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: hsl(243, 80%, 62%);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  .border:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
