.Button {
  color: #eeeeee;
  background-color: #4a5568;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  width: 40%;
  padding: 0.8em 1.2em;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  outline: none;
  &:hover {
    background-color: #1c3d6b;
    cursor: pointer;
  }
}

.ButtonDisabled {
  color: #ffffff6b;
  background-color: #9c9c9c;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  width: 40%;
  padding: 0.8em 1.2em;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  pointer-events: none;
  touch-action: none;
}
