.TextInputLabel {
  margin-bottom: 0.3rem;

  label {
    font-size: 1.3rem;
    font-weight: 500;
    color: #cbd5e0;
  }
}

.InputBoxActive {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 38px;
  width: 100%;
  background: #edf2f7;
  border-radius: 4px;

  input {
    display: block;
    width: 100%;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    border-width: 1px;
    font-size: 1.2rem;
    color: #333333;
    padding: 2px 8px;
    caret-color: #333333;
    outline: none;
  }
}

.InputBoxDisabled {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 38px;
  width: 100%;
  border-radius: 4px;
  pointer-events: none;
  touch-action: none;

  input {
    display: block;
    width: 100%;
    border-radius: 4px;
    background-color: #babcc0;
    border: none;
    border-width: 1px;
    font-size: 1.2rem;
    color: #525252;
    padding: 2px 8px;
  }
}
