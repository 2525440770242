@import './src/styles/abstracts/mixins';
@import './src/styles/base/base';

.Dom {
  background-color: #fff;
  position: relative;
  width: 80vh;
  height: 80vh;

  //1050px
  @media only screen and (max-width: 65.625em) {
    max-width: 80vw;
    max-height: 80vw;
  }
  @media only screen and (max-height: 65.625em) {
    max-width: 75vw;
    max-height: 75vw;
  }
  @include respond(tab-land) {
    max-width: 50vw;
    max-height: 50vw;
  }
  @include respond(tab-port) {
    max-width: 75vw;
    max-height: 75vw;
  }
  //0-432px
  @media only screen and (max-width: 37.5em) {
    max-width: 90vw;
    max-height: 90vw;
  }
}

//Horizontal Layout
.HorizontalContent {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  color: #6b7280;

  @media only screen and (max-width: 65.625em) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1rem;
  }
  .ImageContainer {
    display: flex;
    justify-content: center;
    .Image {
      padding-top: 6%;
      max-width: 85%;
      height: auto;

      @include respond(phone) {
        padding-top: 5%;
      }
    }
  }
  .RecipeGroup {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 85%;
    height: 100%;
  }

  .RecipeTextFraction {
    font-feature-settings: frac;
    font-variant-numeric: diagonal-fractions;
  }
}
//Vertical Layout
.VerticalContent {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.6rem;
  color: #6b7280;

  @media only screen and (max-width: 37.5em) {
    font-size: 1.2rem;
  }
  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .Image {
      padding-left: 5%;
      max-height: 86%;
    }
  }
  .RecipeGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .RecipeTextFraction {
    font-feature-settings: frac;
    font-variant-numeric: diagonal-fractions;
  }
}
