.SelectTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Label {
    margin-bottom: 0.3rem;

    label {
      font-size: 1.3rem;
      font-weight: 500;
      color: #cbd5e0;
    }
  }
}

.Select {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
}
